import { store } from "@/store";
import { defineStore } from "pinia"
import { routes, asyncRoutes } from '@/router'

export const useAppStore = defineStore('bz-admin', {
  state: () => ({
    sidebar: {
      opened: false
    },
    routes: [],
    isAddRouters: false
  }),
  getters: {
    getSidebar: state => {
      return state.sidebar
    },
    getIsAddRouters: state => {
      return state.isAddRouters
    },
  },
  actions: {
    toggleSideBar() {
      this.sidebar.opened = !this.sidebar.opened

      // if (this.sidebar.opened) {
      //   Cookies.set('sidebarStatus', 1)
      // } else {
      //   Cookies.set('sidebarStatus', 0)
      // }
    },
    setRouter() {
      const banzhuLoginInfo = JSON.parse(sessionStorage.getItem('banzhuLoginInfo_b'))
      const preRoutes = filterRoutes(asyncRoutes, banzhuLoginInfo.menuSet)
      this.routes = routes.concat(preRoutes)
      return preRoutes
    },
    setIsAddRouters(isAddRouters) {
      this.isAddRouters = isAddRouters
    }
  },
})
// 过滤路由
const filterRoutes = (routes, keys) => {
  const res = []

  for (const route of routes) {
    const meta = route.meta
    // skip some route
    if (meta.hidden && !meta.canTo) {
      continue
    }

    let data = null

    let onlyOneChild = null
    if (route.children && route.children.length === 1) {
      onlyOneChild = route.children[0].name
    }

    // 开发者可以根据实际情况进行扩展
    for (const item of keys) {
      // 通过路径去匹配
      if (onlyOneChild === item || route.name === item) {
        data = Object.assign({}, route)
      } else if (route.children) {
        // const routePath = pathResolve(basePath, onlyOneChild || route.path)
        const _r = route.children.findIndex(e => e.name === item)
        if (_r != -1) {
          data = Object.assign({}, route)
        }
      }
    }

    // recursive child routes
    if (route.children && data) {
      data.children = filterRoutes(route.children, keys)
    }
    if (data) {

      res.push(data)
    }
  }
  return res
}

export function useAppStoreHook() {
  return useAppStore(store);
}