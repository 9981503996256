import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import login from '../views/login.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Layout from '@/layout'
import { useAppStoreHook } from "@/store/modules/app"

const appStore = useAppStoreHook()

export const routes = [
  {
    path: '/login',
    component: login,
    hidden: true,
    meta: { title: '登录' }
  }, {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard'),
        name: 'Dashboard',
        meta: { title: '主页', icon: 'House' }
      }
    ]
  },
]

export const asyncRoutes = [
  {
    path: '/adver',
    component: Layout,
    redirect: '/adver/index',
    meta: { title: '广告管理', icon: 'setting' },
    children: [
      {
        path: '/adver/index',
        component: () => import('@/views/adver/index'),
        name: 'menu_adver',
        meta: { title: '首屏广告', icon: 'Tickets' }
      }
    ]
  },
  {
    path: '/album',
    component: Layout,
    redirect: '/album/index',
    meta: { title: '活动照片管理', icon: 'setting' },
    children: [
      {
        path: '/album/index',
        component: () => import('@/views/album/index'),
        name: 'menu_album',
        meta: { title: '活动照片管理', icon: 'Camera' }
      }
    ]
  }
]



const router = createRouter({
  history: createWebHashHistory(),
  routes
})



//拦截未登录情况
router.beforeEach((to, from, next) => {
  // 进度条开始
  NProgress.start()
  // 未登录时
  if (!sessionStorage.getItem("banzhuLoginInfo_b")) {
    if (to.path != '/login') {
      return next(`/login?redirect=${to.path}`)
    } else {
      next()
    }
  } else {
    // 已登录，访问login
    if (to.path === '/login') {
      next({ path: '/' })
      return
    }
    // 如果已经加载了路由
    if (appStore.getIsAddRouters) {
      next()
      return
    }
    console.log('重新获取动态的路由')
    // 重新获取动态的路由
    const _routes = appStore.setRouter()
    _routes.forEach(e => {
      router.addRoute(e)
    })
    const redirectPath = from.query.redirect || to.path
    const redirect = decodeURIComponent(redirectPath)
    const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect }
    // 标记已经动态加载路由
    appStore.setIsAddRouters(true)
    next(nextData)
  }

})

router.afterEach(to => {
  document.title = to.meta.title || ''
  NProgress.done()
})

export default router
