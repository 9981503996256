<script setup>
import { ElMessage } from "element-plus";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAppStore } from "@/store/modules/app";
import { sendSmsCode } from "@/api/common";
import { isPhone } from "@/utils/is";
import service from "../utils/request";
import url from "../utils/url";
const utilMd5 = require("../utils/md5.js");

const appStore = useAppStore();

const route = useRoute();
const router = useRouter();

const loading = ref(false);
const passwordType = ref("password");
const loginForm = ref({ phone: "" });
const validatePhone = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入手机号"));
  } else {
    if (!isPhone(value)) {
      callback(new Error("请输入正确的手机号"));
    } else {
      callback();
    }
  }
};
const loginRules = {
  username: [{ required: true, message: "请输入帐号", trigger: "blur" }],
  password: [{ required: true, message: "请输入密码", trigger: "blur" }],
  phone: [{ validator: validatePhone, trigger: "blur" }],
  code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
};

const loginType = ref(0);
onMounted(() => {
  if (route.query.phone) {
    loginType.value = 1;
    loginForm.value.phone = route.query.phone;
  }
});

const togglePwd = () => {
  passwordType.value = passwordType.value == "password" ? "" : "password";
};

const taggertLoginType = (type) => {
  loginType.value = !loginType.value ? 1 : 0;
};
let Codetime = ref(60);
// 倒计时计数
const downCount = () => {
  const timer = setInterval(() => {
    Codetime.value--;
    if (Codetime.value === 0) {
      clearInterval(timer);
      Codetime.value = 60;
    }
  }, 1000);
};
const getEmsCode = () => {
  formRef.value?.validateField("phone", async (v) => {
    if (v) {
      downCount();
      const { phone } = loginForm.value;
      const res = await sendSmsCode({
        phone,
      });
      if (res) {
        ElMessage({
          message: "验证码已发送",
          type: "success",
        });
      }
    }
  });
};

const formRef = ref();
const handleLogin = () => {
  formRef.value?.validate((v) => {
    console.log(v);
    if (v) {
      loading.value = true;

      const { username, password, phone, code } = loginForm.value;
      let params = {
        username,
        password: utilMd5.hexMD5(password),
      };
      // 手机号登录
      if (loginType.value) {
        params = {
          phone,
          code,
        };
      }
      service
        .post(!loginType.value ? url.login : url.loginByPhone, params)
        .then((res) => {
          loading.value = false;
          if (res.data.errno == 0) {
            // 标记已经动态加载路由

            sessionStorage.setItem("banzhuLoginInfo_b", JSON.stringify(res.data.data));
            const routes = appStore.setRouter();
            routes.forEach((e) => {
              router.addRoute(e);
            });
            appStore.setIsAddRouters(true);
            ElMessage({
              message: "登录成功",
              showClose: true,
              type: "success",
            });

            if (route.query.redirect) {
              router.push(route.query.redirect);
            } else {
              router.push("/");
            }
          } else {
            ElMessage({
              message: res.data.errmsg || res.data.msg,
              showClose: true,
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.value = false;
          console.log(
            "%c 🍪 err: ",
            "font-size:20px;background-color: #4b4b4b;color:#fff;",
            err
          );
        });
    }
  });
};
</script>

<template>
  <div class="login-view">
    <el-form
      ref="formRef"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">斑猪后台管理系统</h3>
      </div>
      <!-- 手机号登录 -->
      <el-form-item prop="phone" v-if="loginType">
        <el-input class="input-dom" v-model="loginForm.phone" placeholder="手机号" />
      </el-form-item>
      <el-form-item prop="code" v-if="loginType">
        <el-input class="input-dom" v-model="loginForm.code" placeholder="验证码">
          <template #suffix>
            <el-button
              v-if="Codetime == 60"
              type="primary"
              size="small"
              style="width: 100%"
              @click="getEmsCode"
              >发送验证码</el-button
            >
            <div v-else style="font-size: 13px; color: #999">
              {{ Codetime + "s后重试" }}
            </div>
          </template>
        </el-input>
      </el-form-item>
      <!-- 手机号登录  end-->
      <!-- 帐号登录 -->
      <el-form-item prop="username" v-if="!loginType">
        <el-input class="input-dom" v-model="loginForm.username" placeholder="账号" />
      </el-form-item>

      <el-form-item prop="password" v-if="!loginType">
        <el-input
          class="input-dom"
          :type="passwordType"
          v-model="loginForm.password"
          placeholder="密码"
          @keyup.enter="handleLogin"
        >
          <template #suffix>
            <el-icon
              @click.stop="togglePwd"
              style="cursor: pointer; font-size: 18px; user-select: none"
            >
              <View v-if="passwordType === 'password'" />
              <Hide v-else />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>
      <!-- 帐号登录 end -->
      <el-button
        class="btn-submit"
        :loading="loading"
        type="primary"
        style="width: 100%"
        @click="handleLogin"
        >登 录</el-button
      >
      <div class="more-login" @click="taggertLoginType">
        {{ loginType == 0 ? "使用手机验证码登录" : "使用帐号密码登录" }}
      </div>
    </el-form>
  </div>
</template>

<style lang="less" scoped>
/deep/ input:-webkit-autofill,
/deep/ input:-webkit-autofill:hover,
/deep/ input:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #17233d;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.login-view {
  width: 400px;
  margin: 20vh auto;
  .more-login {
    padding: 15px 0;
    font-size: 13px;
    color: #409eff;
    cursor: pointer;
  }
  .input-dom {
    height: 40px;
    margin-top: 10px;
    border-radius: 50px;
  }

  .btn-submit {
    height: 40px;
    margin-top: 10px;
  }
}
</style>
