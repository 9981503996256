import http from "@/utils/request"
import { ElMessage } from "element-plus"

export const request = {
  post: (url, data, load = true, needLogin = true) => {
    return new Promise((rs, rj) => {
      let params = { ...data }
      if (needLogin) {
        const { token, uid } = JSON.parse(sessionStorage.getItem("banzhuLoginInfo_b"))
        params = {
          ...params,
          token,
          uid
        }
      }

      http
        .post(url, params)
        .then((res) => {
          if (res.data.errno == 0) {
            rs(res.data)
          } else {
            ElMessage({
              message: res.data.errmsg || res.data.msg,
              showClose: true,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(
            "%c 🍪 err: ",
            "font-size:20px;background-color: #4b4b4b;color:#fff;",
            err
          );
        });
    })

  }
}
