<script setup>
import Logo from './logo.vue'
import variables from '@/styles/variables.module.less'
import { routes } from '@/router'
import { useRouter } from "vue-router";
import SidebarItem from './sidebarItem'
import { computed } from 'vue'
import { useAppStoreHook } from "@/store/modules/app";
const pureApp = useAppStoreHook();

const activeMenu = computed(() => {
  const router = useRouter()
  const { meta, path } = router.currentRoute.value

  if (meta.activeMenu) {
    return meta.activeMenu
  }
  return path
})


const asyncRoutes = computed(() => {
  return pureApp.routes
})

const isCollapse = computed(() => {
  return pureApp.getSidebar.opened
})

</script>


<template>
  <div>
    <Logo />
    <div style="height: calc(100% - 50px);">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu class="el-menu-sidebar" :router="true" :collapse="isCollapse" :default-active="activeMenu"
          :collapse-transition="false">
          <SidebarItem v-for="route in asyncRoutes" :key="route.path" :item="route" :base-path="route.path" />
        </el-menu>
      </el-scrollbar>
    </div>
  </div>
</template>

<style lang="less" scoped>
.el-menu {
  border-right: 0;
}

.el-menu-sidebar:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>