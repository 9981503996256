<template>
  <el-dialog
    v-model="dialogVisible"
    title="修改密码"
    width="350px"
    :before-close="handleClose"
  >
    <el-form
      ref="formRef"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <el-form-item prop="password">
        <el-input
          class="input-dom"
          :type="passwordType"
          v-model="loginForm.password"
          autocomplete="new-password"
          placeholder="新密码"
        >
          <template #suffix>
            <el-icon
              @click.stop="togglePwd"
              style="cursor: pointer; font-size: 18px; user-select: none"
            >
              <View v-if="passwordType === 'password'" />
              <Hide v-else />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input class="input-dom" v-model="loginForm.code" placeholder="验证码">
          <template #suffix>
            <el-button
              v-if="Codetime == 60"
              type="primary"
              size="small"
              style="width: 100%"
              @click="getEmsCode"
              >发送验证码</el-button
            >
            <div v-else style="font-size: 13px; color: #999">
              {{ Codetime + "s后重试" }}
            </div>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="setPw">修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ElMessage } from "element-plus";
import { ref } from "vue";
import { sendSmsCode, setpwd } from "@/api/common";
const utilMd5 = require("@/utils/md5.js");
const loginForm = ref({
  password: "",
  code: "",
});

const loginRules = {
  password: [{ required: true, message: "请输入新密码", trigger: "blur" }],
  code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
};

const passwordType = ref("password");
const togglePwd = () => {
  passwordType.value = passwordType.value == "password" ? "" : "password";
};
let Codetime = ref(60);
// 倒计时计数
const downCount = () => {
  const timer = setInterval(() => {
    Codetime.value--;
    if (Codetime.value === 0) {
      clearInterval(timer);
      Codetime.value = 60;
    }
  }, 1000);
};
const getEmsCode = async () => {
  downCount();
  const res = await sendSmsCode({}, true);
  if (res) {
    ElMessage({
      message: "验证码已发送",
      type: "success",
    });
  }
};
const formRef = ref();
const setPw = () => {
  formRef.value?.validate(async (v) => {
    if (v) {
      const res = await setpwd({
        ...loginForm.value,
        password: utilMd5.hexMD5(loginForm.value.password),
      });
      if (res) {
        dialogVisible.value = false;
        ElMessage({
          message: "修改成功",
          type: "success",
        });
      }
    }
  });
};
const dialogVisible = ref(false);
const open = () => {
  dialogVisible.value = true;
};
const handleClose = () => {
  dialogVisible.value = false;
  loginForm.value = {
    password: "",
    code: "",
  };
};

defineExpose({
  open,
});
</script>

<style lang="less" scoped></style>
